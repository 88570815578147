import React, { Component } from 'react';
import '../css/company.css';
import { IMG_URL } from '../js/lib';

export default class CompanyView extends Component {
  render() {
    return (
      <>

        <section
          style={{ 'backgroundImage': `url(${IMG_URL}/bg5.png)` }}
          className="cm-section" data-aos="fade-up">
          <div className="cm-icon"><img src={`${IMG_URL}/company.svg`} /></div>
          <div className="main-title">회사소개</div>
          <div className="sub-title">대한장례의 비전을 소개해드립니다.</div>
          <div className="mouse" >
            <a >
              <span
                id="wh-span"
                style={{ "border": "2px solid white" }} >
              </span>
            </a>
          </div>
        </section>

        <section className="vision-section" data-aos="fade-up">
          <div className="company-title">대한민국 1위 <span>'대한장례' 입니다.</span></div>
          <div className="company-sub-title">마지막 순간, 우리는 많은 어려움을 겪습니다.<br />
            대한장례가 도와드리겠습니다.

          </div>


          <div className="vision-container">
            <div className="vision">
              <div><img src={`${IMG_URL}/consultant.png`} /></div>
              <div>사전 상담 혜택</div>
              <div>대한장례에서 사전상담을 받으시면<br />
                사전상담혜택 할인을 제공해드립니다.
              </div>
            </div>
            <div className="vision">
              <div><img src={`${IMG_URL}/receipt.png`} /></div>
              <div>빠른 접수</div>
              <div>언제든지 전화주시면 신속하고 정확하게<br /> 접수를 도와드립니다.
              </div>
            </div>
            <div className="vision">
              <div><img src={`${IMG_URL}/pro.png`} /></div>
              <div>전문 상담 서비스</div>
              <div>고객들이 보다 정확하고 신속하게 정보를 <br />얻을 수 있도록 전문 상담 서비스를 제공해드립니다.
              </div>
            </div>
          </div>
        </section>


      </>
    )
  }
}