export const list = [
  {
    title: "김*수님의 후기입니다.",
    date: "2024.01.03",
    img: "../img/review.png",
    writer: "김*수",
    content: `
    경황이 없어 가족들끼리 어찌할바를 모르고 있다 동생이 추천한 대한장례를 소개받았습니다.
이미 가입되어있던 상조보다 비용도 저렴하고 경험이 많으신 장례지도사분이 와주셔서 저희 가족은
장례가 처음이였지만 순조롭게 장례를 잘 마무리 되었습니다.<br/><br/>

하나하나 놓치기 쉬운 서류부터 팀장님께서 빠짐없이 잘 챙겨주시고<br/>
상조 도우미 분께서도 음식비용도 많이 나오지 않도록 음식주문도 꼼꼼하게 알려주셔서<br/>
오신 손님들도 불편함 없이 대접을 해드렸습니다.<br/><br/>

오신 손님, 친구, 지인들이 모두 칭찬을 많이 하시점도 상조이용을 참 잘했다 생각이 듭니다.<br/>
입관식때 저희 아버님을 정성스럽게 모셔주신 팀장님 감사합니다.<br/><br/>

자식인 저도 못다한 부분을 가족처럼 잘 모셔주셔서 너무나 감사드리며, 시간이 맞지 않아 <br/>
다음날 안장을 위해 현충원으로 직접 오셔서 서류접수 그리고 아버님 안장까지 저희 팀장님 덕분에<br/>
아버님 편안히 모시게 되었습니다. <br/><br/>

대한장례 소개받고 감동도 받게 되고 친구덕분에 좋은 상조를 사용하게 되어 친구에게도 따로 인사 <br/>
해야겠습니다. 이번 장례에 도움주신 대한장례 직원분들께 고개숙여 감사의 마음을 전합니다.<br/>
감사합니다.<br/><br/>


    `
  },
  {
    title: "하*원님의 후기입니다.",
    date: "2024.01.05",
    img: "../img/review2.png",
    writer: "하*원",
    content: `
    대한장례는 우리 가족에게 큰 도움이 되었습니다. <br/>
    처음에는 우리 가족이 어떻게 해야 할지 모르는 상황이었습니다. <br/><br/>
    우리 동생이 추천한 대한장례의 상조를 알게 되었는데, 그 결과로 가족 모두가 놀라웠습니다.<br/> 
    이미 구독 중이던 상조보다 가격이 저렴하고, 경험이 풍부한 장례 지도사가 함께해서 모든 일이 원활하게 처리되었습니다.<br/>

서류 작업부터 시작해서 음식 주문까지, 대한장례 팀장님과 상조 도우미분들이 모두 세심하게 챙겨주셨습니다.<br/>
 이로 인해 손님들도 편안하게 장례를 마무리할 수 있었습니다. <br/>
오신 손님들, 친구, 지인들도 모두 칭찬을 아끼지 않아 상조 이용을 찬양했습니다.<br/><br/>

입관식 때는 우리 아버님을 정성스럽게 모셔주신 팀장님께 깊은 감사의 말씀을 드립니다. <br/>
 그리고 시간이 맞지 않아 다음날 안장을 위해 현충원까지 직접 오셔서 모든 서류접수와 아버님의 안장까지 도와주신 대한장례 팀장님의 노고에 진심으로 감사드립니다.<br/><br/>

이번에 대한장례를 통해 감동을 받았고, <br/>
친구의 소개 덕분에 좋은 상조를 이용할 수 있었습니다.<br/>

그래서 친구에게도 따로 감사의 인사를 전하고 싶습니다. <br/>
이 모든 일에 도움을 주신 대한장례 직원 분들께 진심으로 고개 숙여 감사의 말씀을 전합니다.<br/>

    `
  },
  {
    title: "김*철님의 후기입니다.",
    date: "2024.02.21",
    img: "../img/review3.png",
    writer: "김*철",
    content: `
    지난달 가족 장례를 대한장례에서 치르게 되었습니다.<br/>
     처음으로 장례 준비를 맡게 되어 막막하고 어려움이 많았는데,<br/> 
     대한장례의 전문적이고 세심한 서비스 덕분에 많은 도움을 받았습니다.<br/> 

    장례 절차를 처음부터 끝까지 친절하게 안내해주셔서 큰 위로가 되었습니다.<br/><br/>

장례식장의 시설도 깔끔하고 조용했습니다.<br/> 

방문한 조문객들도 편안하게 장소를 이용할 수 있었고,<br/> 
장례 진행에 있어서 필요한 모든 것이 잘 준비되어 있었습니다.<br/>

특히, 조화와 장식에서 세심한 배려가 느껴졌으며, 가족들의 의견을 잘 반영해 주셨습니다.<br/><br/>

대한장례의 직원 분들은 모두 전문성과 인간미를 겸비하고 계셨습니다.<br/> 
어려운 시기, 가족들에게 따뜻한 위로가 되어주셨고, <br/>
장례 과정 중 발생할 수 있는 작은 문제들도 신속하게 해결해 주셨습니다. <br/>
이런 점들이 대한장례를 믿고 맡길 수 있는 이유라고 생각합니다.
    `
  },
];


export function chk() {
  document.addEventListener('readystatechange', event => { //윈도우 로딩될때 프로그레스바 그려줌.

    if (event.target.readyState === "complete") {
      const name = window.location.pathname;
      if (name == "/review") {
        drawList();
      } else {
        drawItem(name.replace("/review/", ""))
      }
    }
  });
}



export function drawList() {
  const section = document.getElementById("review-section");
  section.innerHTML = "";
  list.map((item, id) => {
    section.innerHTML += `
    <a href="review/${id}">
    <div class="review-item">
      <div class="review-img-con">
        <img src=${item.img} />
      </div>
      <div class="review-title">${item.title}</div>
      <div class="review-date">${item.date}</div>
    </div>
    </a>
    `
  })
};

export function drawItem(id) {
  
  const item = document.getElementById("review-page");
  const target = list[id];
  item.innerHTML = `
  <div class="title">${target.title}</div>
  <div class="info">
    <div class="writer">작성자 : ${target.writer}</div>
    <div class="date">날짜 : ${target.date}</div>
  </div>
  <div class="contents">
  ${target.content}
  </div>
  
  `
}
