import React from 'react';
import '../css/pass.css';
import { IMG_URL } from '../js/lib';

export const Pass = () => {
  return (
    <div className='ticket'>
      <div className="boarding-pass">
        <header>
          <div className='logo'>
            <img src={`${IMG_URL}/logo-w.png`} />
          </div>
          <div className="flight">
            <small>flight</small>
            <strong>DH 356</strong>
          </div>
        </header>
        <section className="cities">
          <div className="city">
            <small>KOREA</small>

            <strong>KOR</strong>
          </div>
          <div className="city">
            <small>YOUR COUNTRY</small>
            <strong>?</strong>
          </div>
            <AirplaneIcon/>
        </section>
        <section className="infos">
          <div className="places">
            <div className="box">
              <small>Terminal</small>
              <strong><em>W</em></strong>
            </div>
            <div className="box">
              <small>Gate</small>
              <strong><em>C3</em></strong>
            </div>
            <div className="box">
              <small>Seat</small>
              <strong>14B</strong>
            </div>
            <div className="box">
              <small>className</small>
              <strong>E</strong>
            </div>
          </div>
          <div className="times">
            <div className="box">
              <small>Boarding</small>
              <strong>19:50</strong>
            </div>
            <div className="box">
              <small>Departure</small>
              <strong>20:20</strong>
            </div>
            <div className="box">
              <small>Duration</small>
              <strong>01:10</strong>
            </div>
            <div className="box">
              <small>Arrival</small>
              <strong>21:30</strong>
            </div>
          </div>
        </section>
        <section className="strap">
          <div className="box">
            <div className="passenger">
              <small>passenger</small>
              <strong>대한장례가 함께합니다.</strong>
            </div>
            <div className="pass-date">
              <small>Date</small>
              <strong>Tue, 15 march {new Date().getFullYear()}</strong>
            </div>
          </div>
          <div className='qrcode'>
            <img src={`${IMG_URL}/qr.png`} />
          </div>

        </section>
      </div>
    </div>

  )
}

const AirplaneIcon = () => (
  <div className='airplane'>
    <img src={`${IMG_URL}/flight.svg`} />
  </div>
);
