import React, { Component } from 'react';
import '../css/home.css';
import '../css/call.css';
import { IMG_URL, TEL } from '../js/lib';
import Carousel from './Carousel';
import { Pass } from "./pass";
import ProductCarousel from './ProductCarousel';
import StickManAnimation from "./StickManAnimation"
import  { First,MainBG,Second, Third } from './First';
import MediaQuery from 'react-responsive'
import { PhoneClick } from '../js/gtag';

export default class Home extends Component {
  state = {
    flag: [0, 0, 0, 0],
  }

  setFlag(idx, f) {
    let tmp = this.state.flag;

    tmp[idx] = f;
    this.setState({
      flag: tmp
    })
    
  }

 
  render() {
    const { flag } = this.state;
   
    return (
      <>
      <MainBG/>

        <div className="home-title m-t">
          상품안내
          <div className='mid-title'>
            <div><img src={`${IMG_URL}/deco3-w.png`} className='deco1-2' /></div>
            <div className='mid-txt'>100만원에 모든 장례 진행</div>
            <div><img src={`${IMG_URL}/deco3-w.png`} className='deco1-2' /></div>
          </div>
        </div>


        <div className="card-tel-con" data-aos="fade-up">

          <div className='card-title'>임종시</div>
          <StickManAnimation />

        </div>


        <div className='carousel-bg' data-aos="fade-up">
          <ProductCarousel />
        </div>


        <section className="solution-section" data-aos="fade-up">

          <div className="product-container">

            <table>
              <tr>
                </tr>
              <tr>
                <th className="category">장의품목<br />
                  <MediaQuery minWidth={700}>(고인용품)</MediaQuery>
                </th>
                <td>
                  <div>
                    <div className="item-img"><img src="./img/1.png" /></div>
                    <div className='item-title'>오동나무(특관)</div>
                    <div>오동나무는 흡습력이 좋고 가벼우면서도<br /> 마찰에 강하여 오래전부터 관제로 되어왔습니다.</div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img"><img src="./img/2.png" /></div>
                    <div className='item-title'>삼베수의</div>
                    <div>삼베수의를 함께 제공해 드립니다.</div>

                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img"><img src="./img/3.png" /></div>
                    <div className='item-title'>입관용품</div>
                    <div>염베, 관보, 명정, 칠성관 소창 등등 기타 (50가지)를<br /> 함께 제공 해드립니다.</div>
                  </div>
                </td>
              </tr>


              <tr>
                <th className="category">입관 지원</th>
                <td colSpan={3}>
                  <div>
                    <div className="item-img"><img src="./img/4.png" /></div>
                    <div className='item-title'>장례지도사 2인</div>
                    <div>친절하고 전문적인 장례지도사들이 도와드립니다.</div>
                  </div>
                </td>

              </tr>

              <tr>
                <th className="category">상례복</th>
                <td>
                  <div>
                    <div className="item-img-2"><img src={`${IMG_URL}/5.svg`} /></div>
                    <div className='item-title'>여자 상례복 3벌</div>
                    <div>여자 상례복 3벌 제공해드립니다.</div>

                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img-2"><img src={`${IMG_URL}/6.svg`} /></div>
                    <div className='item-title'>남자 상례복 3벌</div>
                    <div>남자 상례복 3벌 제공해드립니다.</div>
                  </div>
                </td>
                <MediaQuery minWidth={700}>
                <td >
                <div>
                    <div></div>
                    <div className='item-title'></div>
                    <div></div>
                    </div>
                    
                  </td>
                  </MediaQuery>
              </tr>

              <tr>
                <th className="category">차량지원</th>
                <td>
                  <div>
                    <div className="item-img"><img src={`${IMG_URL}/7.png`} /></div>
                    <div className='item-title'>앰뷸런스 지원</div>
                    <div>앰뷸런스를 지원해드립니다.</div>

                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img"><img src={`${IMG_URL}/8.png`} /></div>
                    <div className='item-title'>장의버스 + 리무진</div>
                    <div>장의버스 + 리무진을 제공해드립니다.</div>
                  </div>
                </td>
                <MediaQuery minWidth={700}>
                <td >
                <div>
                    <div></div>
                    <div className='item-title'></div>
                    <div></div>
                  </div>
                  </td>
                  </MediaQuery>
              </tr>

              <tr>
                <th className="category">유골함 지원</th>
                <td colSpan={3}>
                  <div>
                    <div className="item-img"><img src={`${IMG_URL}/1.png`} /></div>
                    <div className='item-title'>오동나무 유골함 지원</div>
                    <div>오동나무 유골함을 지원해드립니다.</div>

                  </div>
                </td>
              </tr>

              <tr>
                <th className="category-2">할인 혜택가</th>
                <td colSpan={3}>
                  <div>
                    <div className='item-money'>100만원</div>
                    <div className='item-sub-money'>지금 바로 상담받아보세요.</div>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td colSpan={3}>
                  
                    <div className='item-money-2'>개장ㆍ이장ㆍ석물 전문업체 </div>
                </td>
              </tr>
          
              <div>
              </div>
            </table>

                <div className='notice-txt'>
                  *위의 금액을 제외하고, 가족분들은 음식값만 지불하시면 됩니다.*
                </div>
          </div>
          <div className="flower-bg">
            <img src="./img/flower.png" />
          </div>
        </section>

        <section>

          <div className='foreign-container'>
            <div className='foreign-title-con'>
              <div className='foreign-deco'><img src={`${IMG_URL}/deco1.png`} /></div>
              <div className='foreign-title'>외국인 장례 전문 업체</div>
              <div className='foreign-deco'><img src={`${IMG_URL}/deco1.png`} /></div>
            </div>
            <div>
              <div className='foreign-sub-title'>대사관. 영사관. 항공권. 시신발부필증. 사망진단서</div>
              <div className='pass-con'>
                <Pass />
              </div>
              <div className='foreign-txt-con'>
                <div className='foreign-txt-inner'>
                  <div className='foreign-sub-title2'>각종 서류 대행</div>

                  <div className='foreign-sub-title2'> 화장부터 본국 생장 이송까지 성심성의껏 해드립니다.</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="goal-section" data-aos="fade-up" >
          <div className="home-title">대한장례의 목표</div>
          <div className="goal-container">

            <div className="goal-txt-container-mobile">
              <div className="goal-left">
                <div className="goal-txt">
                  <div className="goal-txt-title">간단한 장례절차</div>
                  <div className="goal-txt-sub">전문성에 기반하여<br />간편한 절차 제공</div>
                </div>

                <div className="goal-txt">
                  <div className="goal-txt-title">신뢰기반 소통</div>
                  <div className="goal-txt-sub">신뢰를 바탕으로 전문성을 발휘하며 <br />진심으로 소통</div>
                </div>

              </div>
            </div>
            <div className="goal-border">
              <div className="goal-bg">
                <img src="./img/goal.png" />
              </div>
            </div>
            <div className="goal-txt-container">
              <div className="goal-left">
                <div className="goal-txt">
                  <div className="goal-txt-title">간단한 장례절차</div>
                  <div className="goal-txt-sub">전문성에 기반하여<br />간편한 절차 제공</div>
                </div>

                <div className="goal-txt">
                  <div className="goal-txt-title">신뢰기반 소통</div>
                  <div className="goal-txt-sub">신뢰를 바탕으로 전문성을 발휘하며 <br />진심으로 소통</div>
                </div>

              </div>
              <div className="goal-right">
                <div className="goal-txt">
                  <div className="goal-txt-title">체계적인 상조 서비스</div>
                  <div className="goal-txt-sub">체계적으로 구성된<br />상조서비스 제공</div>
                </div>

                <div className="goal-txt">
                  <div className="goal-txt-title">높은 만족도</div>
                  <div className="goal-txt-sub">고객의 만족을 최우선으로 하며<br />책임감을 가지고 높은 수준의 서비스를 제공</div>
                </div>

              </div>

            </div>
            <div className="goal-txt-container-mobile">

              <div className="goal-right">
                <div className="goal-txt">
                  <div className="goal-txt-title">체계적인 상조 서비스</div>
                  <div className="goal-txt-sub">체계적으로 구성된<br />상조서비스 제공</div>
                </div>

                <div className="goal-txt">
                  <div className="goal-txt-title">높은 만족도</div>
                  <div className="goal-txt-sub">고객의 만족을 최우선으로 하며<br />책임감을 가지고 높은 수준의 서비스를 제공</div>
                </div>

              </div>

            </div>

          </div>
        </section>
        <div className="stage">
          <div>
            <div className="stage-title">이용절차</div>
            <div className="stage-sub">대한장례의 이용절차를<br />안내드립니다.</div>
            <div className="stage-btn">
              <a href={`tel:${TEL}`}
              onClick={() => {
                PhoneClick();
              }}
                className="tel-click">
                직접 상담 하기
              </a>
            </div>
          </div>
          <div className="stage-right">
            <div className="stage-img-con">
              <div className="stage-img"><img src={`${IMG_URL}/step1.png`} /></div>
              <div>1. 장례접수</div>
            </div>
            <div className="stage-arr-img"><img src={`${IMG_URL}/arr.png`} /></div>
            <div className="stage-img-con">
              <div className="stage-img"><img src={`${IMG_URL}/step2.png`} /></div>
              <div >2. 장례지도사 배정</div>
            </div>
            <div className="stage-arr-img"><img src={`${IMG_URL}/arr.png`} /></div>
            <div className="stage-img-con">
              <div className="stage-img"><img src={`${IMG_URL}/step3.png`} /></div>
              <div>3. 장례진행</div>
            </div>
            <div className="stage-arr-img"><img src={`${IMG_URL}/arr.png`} /></div>
            <div className="stage-img-con">
              <div className="stage-img"><img src={`${IMG_URL}/step4.png`} /></div>
              <div>4. 결제</div>
            </div>

          </div>
        </div>

        <section className="review-section" data-aos="fade-up">
          <div className="review-title-container">
            <div className="review-title-container-inner">
              <div>장례후기를 만나보세요.</div>
              <div><a href="./review">더보기</a></div>
            </div>
          </div>
          <div className="review-list">
            <a href="./review/0">
              <div className="review-container">
                <div className="review-img-con">
                  <img src={`${IMG_URL}/review.png`} />
                </div>
                <div className="review-title">김*수님의 후기입니다.</div>
                <div className="review-date">2024.01.03</div>
              </div>

            </a>
            <a href="./review/1">
              <div className="review-container">
                <div className="review-img-con">
                  <img src={`${IMG_URL}/review2.png`} />
                </div>
                <div className="review-title">하*원님의 후기입니다.</div>
                <div className="review-date">2024.01.05</div>
              </div>
            </a>
            <a href="./review/2">
              <div className="review-container">
                <div className="review-img-con">
                  <img src={`${IMG_URL}/review3.png`} />
                </div>
                <div className="review-title">김*철님의 후기입니다.</div>
                <div className="review-date">2024.02.21</div>
              </div>

            </a>
          </div>
        </section>

        <section className="vision-section" data-aos="fade-up">
          <div className="vision-title-con">
            <div><span>특별한 준비가</span> 필요한 순간,</div>
            <div><span>내 가족 처럼</span> 대한장례가 함께 하겠습니다.</div>
          </div>
          <div className="vision-seciton-inner">
            <div className="left-section">
              <div>D</div>
              <div>A</div>
              <div>E</div>
              <div>H</div>
              <div>A</div>
              <div>N</div>
              <div className="h-line"></div>
            </div>
            <div>
              <div className="vision-top">
                <div className="vision-item"
                  onMouseOver={() => { this.setFlag(0, 1) }} onMouseOut={() => { this.setFlag(0, 0) }}>
                  <div className="vision-img-div">
                    <img src={`${IMG_URL}/vision1.png`} />
                    <div className="overlay" style={{ 'opacity': `${flag[0] ? 1 : 0}` }}>
                      <p>좋은 장례지도사</p>
                    </div>
                  </div>
                </div>
                <div className="vision-item"

                  onMouseOver={() => { this.setFlag(1, 1) }} onMouseOut={() => { this.setFlag(1, 0) }}>
                  <div className="vision-img-div">
                    <img src={`${IMG_URL}/vision2.png`} />
                    <div className="overlay" style={{ 'opacity': `${flag[1] ? 1 : 0}` }}>
                      <p>고객 감동</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision-btm">
                <div className="vision-item"
                  onMouseOver={() => { this.setFlag(2, 1) }} onMouseOut={() => { this.setFlag(2, 0) }}>
                  <div className="vision-img-div">
                    <img src={`${IMG_URL}/vision3.png`} />
                    <div className="overlay" style={{ 'opacity': `${flag[2] ? 1 : 0}` }}>
                      <p>소통과 나눔</p>
                    </div>
                  </div>
                </div>
                <div className="vision-item"
                  onMouseOver={() => { this.setFlag(3, 1) }} onMouseOut={() => { this.setFlag(3, 0) }}>
                  <div className="vision-img-div">
                    <img src={`${IMG_URL}/vision4.png`} />
                    <div className="overlay" style={{ 'opacity': `${flag[3] ? 1 : 0}` }}>
                      <p>맞춤 컨설팅</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MediaQuery minDeviceWidth={800}>
          <div className='hg-ment' data-aos="fade-up">
        <span>100만원에</span> 이 모든걸 할수있는 상조회사는<br/> 여기 <span>대한장례</span> 뿐입니다.
        </div>
        </MediaQuery>
        </section>
        <div className="vision-seciton-inner-mobile">
          <Carousel flag={flag} setFlag={this.setFlag} />
        </div>
        <MediaQuery maxDeviceWidth={800}>
          <div className='hg-ment' data-aos="fade-up">
        <span>100만원에</span> 이 모든걸 할수있는<br/> 상조회사는 여기 <span>대한장례</span> 뿐입니다.
        </div>
        </MediaQuery>
        <div className="btm-img">
          <img src={`${IMG_URL}/bg-btm.png`} />
          
            </div>
            
      </>
    )
  }
}