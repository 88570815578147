import React, { useState } from 'react';
import Lottie from 'lottie-react';
import { TEL,IMG_URL} from "../js/lib";
import "../css/call.css";
import stickManAnimation from './people.json'; // 파일 경로에 따라 수정 필요
import { PhoneClick } from '../js/gtag';


function StickManAnimation() {
  const [animationRunning, setAnimationRunning] = useState(false);

  function mouseEnter() {
    if (!animationRunning) {
      setAnimationRunning(true);
    }
  }

  function mouseLeave() {
    setAnimationRunning(false);
  }

  // function startLoop() {
  //   // Looping logic here
  // }

  return (
    <div className="zone">
      <button onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <span className={`bodymovin ${animationRunning ? '' : 'bodymovin--hidden'}`}>
          <Lottie
            animationData={stickManAnimation}
            loop={true}
            autoplay={true}
            // onLoopComplete={startLoop}
            speed={2.6}
            segments={[0, 8]}
          />
        </span>
        <a href={`tel:${TEL}`}
          onClick={() => {
            PhoneClick();
          }}>
          <div className='text tel-click'>
            <img src={`${IMG_URL}/emer.svg`} />
            {TEL}
            <img src={`${IMG_URL}/emer.svg`} />
            </div>
          </a>
      </button>
    </div>
  );
}

export default StickManAnimation;