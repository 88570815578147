
import $ from 'jquery';
import _ from 'lodash';
export const IMG_URL = `${process.env.PUBLIC_URL}/img`;
export const TEL = "1661-0155";

export function loading(){
  document.addEventListener('readystatechange', event => { //윈도우 로딩될때 프로그레스바 그려줌.

    // let loadingTimer;

    if (event.target.readyState === "interactive") {
      // loadingTimer = setTimeout(showloading, 300);
    }
      
    // if (event.target.readyState === "complete") {
    //   const name = window.location.pathname;
    //   console.log("here? ", name);
    //   var menu = document.getElementById("menu-container");
    //   const buger = document.getElementById("buger");
      
    //   if (name === "/home") {
    //     menu.classList.remove("active"); // active 클래스 제거
    //     buger.style.color="black";
    // } else {
    //   buger.style.color="white";
    //     menu.classList.add("active"); // active 클래스 추가
    //   }
    //   // clearTimeout(loadingTimer);
    //   // hideloading();
    // }
  });
  
  
}
// $(document).ready(function () {
//   hideloading();
//   // ReactDOM.render(<Wrapper />, document.getElementById('app'));
// });
// function showloading() {
//   const indicator = document.getElementById("loadingIndicator");
//   if (!indicator) return;
//   indicator.style.display = "block";
// }

// function hideloading() {
//   const indicator = document.getElementById("loadingIndicator");
//   if (!indicator) return;
//   indicator.style.display = "none";
//   indicator.style.opacity = "1";
// }



export function menuClick() {
  var x = document.getElementById("navi");
  const buger = document.getElementById("buger");
  const root = document.getElementById("root");
  const name = window.location.pathname;
  if (x.style.display === "flex") {
    x.style.display = "none";
;    if (name === "/home") {
      buger.style.color="white";
  } else {
    buger.style.color="white";
    }

    root.style.position = '';
  } else {
    x.style.display = "flex";
    buger.style.color = "white";
    root.style.position="fixed"
      
  }

}


$(window).scroll(function() {
    var winScrollTop = $(window).scrollTop();
    var winHeight = $(window).height();
    var floaterHeight = $('#floater').outerHeight(true);
    var fromBottom = 20;

  if ($(window).width() > 600) {
    var top = winScrollTop + winHeight - floaterHeight - fromBottom;
    $('#floater').css({'top': top + 'px'});
  }
});

