import React, { useLocation, useState, useEffect } from 'react';
import { Route } from 'react-router';
import { Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { IMG_URL, TEL, loading, menuClick } from '../js/lib';
import Product from '../view/Product';
import Home from '../view/Home';
import NotFound from '../view/404';
import CompanyView from '../view/CompanyView';
import Review from '../view/Review';
import ReviewItem from "../view/ReviewItem";
import AOS from "aos";
import "aos/dist/aos.css";
import '../js/lib';
import "../css/index.css";
import "../css/layout.css";
import "../js/gtag";
import RouteChangeTracker, { PhoneClick } from '../js/gtag';
import TagManager from 'react-gtm-module';
import TextTransition, { presets } from 'react-text-transition';
import ReactGA from "react-ga4";

export default function MainRouter() {
  RouteChangeTracker();
  const [curYear, setCurYear] = useState(new Date().getFullYear() - 1999);
  const [index, setIndex] = useState(0);
  // const [chg, setChg] = useState(false);
  // const [chg2, setChg2] = useState(true);
  useEffect(() => {
    TagManager.initialize({
      gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID}`
    });

    AOS.init({
      duration: 500,
      delay: 150,
      once: true
    });

    
    document.onreadystatechange = function () {

      let loadingTimer;

      if (document.readyState === 'complete') {
        clearTimeout(loadingTimer); // 로딩 타이머 클리어

        hideloading(); // 로딩 바 숨김

        const name = window.location.pathname;
        var menu = document.getElementById("menu-container");
        const buger = document.getElementById("buger");
        if (name === "/home") {
          menu.classList.remove("active"); // active 클래스 제거
          buger.style.color = "white";
        } else {
          buger.style.color = "white";
          menu.classList.add("active"); // active 클래스 추가
        }
      } else {
        loadingTimer = setTimeout(showloading, 300); // 로딩 바 표시
      }
    }

    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("safari") > -1) {
      showloading();
      const name = window.location.pathname;
      var menu = document.getElementById("menu-container");
      const buger = document.getElementById("buger");
      if (name === "/home") {
        menu.classList.remove("active"); // active 클래스 제거
        buger.style.color = "white";
      } else {
        buger.style.color = "white";
        menu.classList.add("active"); // active 클래스 추가
      }
    }

    setTimeout(() => {
      hideloading();
    }, 300);
  }, []);



  function showloading() {
    const indicator = document.getElementById("loadingIndicator");
    if (!indicator) return;
    indicator.style.display = "block";
  }
  function hideloading() {
    const indicator = document.getElementById("loadingIndicator");
    if (!indicator) return;
    indicator.style.display = "none";
    indicator.style.opacity = "1";
  }
  hideloading();
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(prevChg => !prevChg),
      6000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  
  const DESKTOP_TEXTS = [`${curYear}전통의 믿음과 신뢰가 있는 대한장례 의전`, '100만원에 모든장례 진행'];
  const MOBILE_TEXTS = [`${curYear}전통의 대한장례 의전`, '100만원에 모든장례 진행'];
  return (
    <div>
      <div className='banner'>
        <div className='banner-desktop'>
          <div className={index % 2 === 0 ? 'banner-txt active' : 'banner-txt'}>
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
            {DESKTOP_TEXTS[0]}
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
          </div>

          <div className={index % 2 !== 0 ? 'banner-txt active' : 'banner-txt'}>
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
            {DESKTOP_TEXTS[1]}
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
          </div>
        </div>
        <div className='banner-mobile'>
        <div className={index % 2 === 0 ? 'banner-txt active' : 'banner-txt'}>
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
            {MOBILE_TEXTS[0]}
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
          </div>

          <div className={index % 2 !== 0 ? 'banner-txt active' : 'banner-txt'}>
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
            {MOBILE_TEXTS[1]}
            <img src={`${IMG_URL}/flower-top.png`} className='top-flower' />
          </div>
          
        </div>
        
      </div>
      <div id="menu-container">
        <ul>
          <li><a href="../home">홈</a></li>
          <li><a href="../company">회사소개</a></li>
          <li><a href="../review">이용후기</a></li>
          <li><a href="../product">상품소개</a></li>
        </ul>
      </div>
      <div id="menu-container-mobile">

        <ul id="navi">
          <li><a href="../home">홈</a></li>
          <li><a href="../company">회사소개</a></li>
          <li><a href="../review">이용후기</a></li>
          <li><a href="../product">상품소개</a></li>
        </ul>
        <a className="icon" id="buger-a" onClick={() => { menuClick() }}>
          <i className="fa fa-bars" id="buger"></i>
        </a>
      </div>

      <div className="body-container">
          
          <Routes>
            <Route path='/' element={<Navigate to="/home" />} />
            <Route path={"/home"} element={<Home />} />
            <Route path={"/company"} element={<CompanyView />} />
            <Route path={"/product"} element={<Product />} />
            <Route path={"/review"} element={<Review />} />
            <Route path={"/review/:id"} element={<ReviewItem />} />
            <Route path={"/*"} element={<NotFound />} />
          </Routes>
        
      </div>
      <footer className="footer">

        <div className="footer-inner">

          <a href={`tel:${TEL}`}
            onClick={() => {
              PhoneClick();
            }}
            >
            <div className='footer-tel tel-click'>
              {TEL}
            </div>
          </a>
          <div className='horizontal-line'></div>
          <div className="footer-addr-txt">부산시 부산진구 개금온정로 17번길  38</div>
          <div className="footer-addr-txt">사업자등록번호 : 746-95-01120</div>
          <div className="footer-txt">COPYRIGHTⒸ 2024 DAEHAN FUNERAL ALL RIGHTS RESERVED.</div>
        </div>
      </footer>
      <div id="floater">
        <a href={`tel:${TEL}`}
          onClick={() => {
            PhoneClick();
          }}
          className='tel-click'>
          <img src="../img/call.svg" className="call-img" />
        </a>
      </div>
      <div id="loadingIndicator" className="preloader">
        <div id="loadingBar" className="loading-bar"></div>
      </div>

    </div >
  );

}