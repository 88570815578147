import React, { Component } from 'react';
import '../css/product.css';
import { IMG_URL, TEL } from '../js/lib';
import { Pass } from "./pass";
import ProductCarousel from './ProductCarousel';
import StickManAnimation from './StickManAnimation';
import MediaQuery from 'react-responsive'

export default class Product extends Component {
  render() {
    return (
      <>
        <section
          style={{ 'backgroundImage': `url(${IMG_URL}/bg4.png)` }}
          className="cm-section" data-aos="fade-up">
          <div className="cm-icon"><img src={`${IMG_URL}/product.svg`} /></div>

          <div className="main-title">상품소개</div>
          <div className="sub-title">대한장례의 상품을 소개해드립니다.</div>
          <div className="mouse" >
            <a >
              <span id="wh-span" style={{ "border": "2px solid white" }} >
              </span>
            </a>
          </div>
        </section>

        <section>

          <div className='foreign-container'>
            <div className='foreign-title-con'>
              <div className='foreign-deco'><img src={`${IMG_URL}/deco1.png`} /></div>
              <div className='foreign-title'>외국인 장례 전문 업체</div>
              <div className='foreign-deco'><img src={`${IMG_URL}/deco1.png`} /></div>
            </div>
            <div>
              <div className='foreign-sub-title'>대사관. 영사관. 항공권. 시신발부필증. 사망진단서</div>
              <div className='pass-con'>
                <Pass />
              </div>
              <div className='foreign-txt-con'>
                <div className='foreign-txt-inner'>
                  <div className='foreign-sub-title2'>각종 서류 대행</div>

                  <div className='foreign-sub-title2'> 화장부터 본국 생장 이송까지 성심성의껏 해드립니다.</div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div className="card-tel-con" data-aos="fade-up">

          <div className='card-title'>임종시</div>
          <StickManAnimation />

        </div>

        <div className='carousel-bg'>
          <ProductCarousel />
        </div>


        <section className="solution-section" data-aos="fade-up">

          <div className="product-container">

            <table>
              <tr>
              </tr>
              <tr>
                <th className="category">장의품목<br />
                <MediaQuery minWidth={700}>
                    (고인용품)
                </MediaQuery>
                </th>
                <td>
                  <div>
                    <div className="item-img"><img src="./img/1.png" /></div>
                    <div className='item-title'>오동나무(특관)</div>
                    <div>오동나무는 흡습력이 좋고 가벼우면서도<br /> 마찰에 강하여 오래전부터 관제로 되어왔습니다.</div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img"><img src="./img/2.png" /></div>
                    <div className='item-title'>삼베수의</div>
                    <div>삼베수의를 함께 제공해 드립니다.</div>

                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img"><img src="./img/3.png" /></div>
                    <div className='item-title'>입관용품</div>
                    <div>염베, 관보, 명정, 칠성관 소창 등등 기타 (50가지)를<br /> 함께 제공 해드립니다.</div>
                  </div>
                </td>
              </tr>


              <tr>
                <th className="category">입관 지원</th>
                <td colSpan={3}>
                  <div>
                    <div className="item-img"><img src="./img/4.png" /></div>
                    <div className='item-title'>장례지도사 2인</div>
                    <div>친절하고 전문적인 장례지도사들이 도와드립니다.</div>
                  </div>
                </td>

              </tr>

              <tr>
                <th className="category">상례복</th>
                <td>
                  <div>
                    <div className="item-img-2"><img src={`${IMG_URL}/5.svg`} /></div>
                    <div className='item-title'>여자 상례복 3벌</div>
                    <div>여자 상례복 3벌 제공해드립니다.</div>

                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img-2"><img src={`${IMG_URL}/6.svg`} /></div>
                    <div className='item-title'>남자 상례복 3벌</div>
                    <div>남자 상례복 3벌 제공해드립니다.</div>
                  </div>
                </td>
                <MediaQuery minWidth={700}>
                  <td >
                    <div>
                      <div></div>
                      <div className='item-title'></div>
                      <div></div>
                    </div>

                  </td>
                </MediaQuery>
              </tr>

              <tr>
                <th className="category">차량지원</th>
                <td>
                  <div>
                    <div className="item-img"><img src={`${IMG_URL}/7.png`} /></div>
                    <div className='item-title'>앰뷸런스 지원</div>
                    <div>앰뷸런스를 지원해드립니다.</div>

                  </div>
                </td>
                <td>
                  <div>
                    <div className="item-img"><img src={`${IMG_URL}/8.png`} /></div>
                    <div className='item-title'>장의버스 + 리무진</div>
                    <div>장의버스 + 리무진을 제공해드립니다.</div>
                  </div>
                </td>
                <MediaQuery minWidth={700}>
                  <td >
                    <div>
                      <div></div>
                      <div className='item-title'></div>
                      <div></div>
                    </div>
                  </td>
                </MediaQuery>
              </tr>

              <tr>
                <th className="category">유골함 지원</th>
                <td colSpan={3}>
                  <div>
                    <div className="item-img"><img src={`${IMG_URL}/1.png`} /></div>
                    <div className='item-title'>오동나무 유골함 지원</div>
                    <div>오동나무 유골함을 지원해드립니다.</div>

                  </div>
                </td>
              </tr>

              <tr>
                <th className="category-2">할인 혜택가</th>
                <td colSpan={3}>
                  <div>
                    <div className='item-money'>100만원</div>
                    <div className='item-sub-money'>30% - 무료까지 할인 제공.</div>
                  </div>
                </td>
              </tr>


              <div>
              </div>
            </table>

            <div className='notice-txt'>
              *위의 금액을 제외하고, 가족분들은 음식값만 지불하시면 됩니다.*
            </div>
          </div>
          <div className="flower-bg">
            <img src="./img/flower.png" />
          </div>
        </section>

        </>
        )
  }
}