import React, { Component } from 'react';
import Slider from "react-slick";
import { IMG_URL } from '../js/lib';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class ProductCarousel extends Component {
  render() {
    var settings = {
      className: "center",
      centerMode: true,
      arrows:false,
      infinite: true,
      autoplay:true,
      centerPadding: "60px",
      slidesToShow: 3,
      speed: 500,
      dots:false,
      slidesPerRow: 1,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
        }
      }],
    }

    return (

      <Slider {...settings} className='slider-bg'>

        <div className='sub-card carousel-item'>
          <div className="card-img"><img src={`${IMG_URL}/ground-select.png`} /></div>
          <div className='card-txt'>장례식장 선정</div>
        </div>

        <div className='sub-card carousel-item'>
          <div className="card-img"><img src={`${IMG_URL}/move.png`} /></div>
          <div className='card-txt'>운구 이송</div>
        </div>

        <div className='sub-card carousel-item'>
          <div className="card-img"><img src={`${IMG_URL}/alarm.png`} /></div>
          <div className='card-txt'>부고 알림 서비스</div>
        </div>

        <div className='sub-card carousel-item'>
          <div className="card-img"><img src={`${IMG_URL}/select.png`} width={'11rem'}/></div>
          <div className='card-txt'>장지 선정</div>
        </div>


      </Slider >
      // <div>

    )
  }
}