import React, { Component } from 'react';
import Slider from "react-slick";
import { IMG_URL } from '../js/lib';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default class Carousel extends Component {
  render() {
    var settings = {
      dots: TextTrackCueList,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows:false,
    };
    const { flag, setFlag } = this.props;
    return (
      <Slider {...settings}>

        <div
          className="vision-item"

        >
          <div className="vision-img-div-mobile">
            <img src={`${IMG_URL}/vision1.png`} />
            <div className="vision-mobile-txt">좋은 장례지도사</div>
          </div>
        </div>
        <div className="vision-item"
        >
          <div className="vision-img-div-mobile">
            <img src={`${IMG_URL}/vision2.png`} />

            <div className="vision-mobile-txt">고객 감동</div>
          </div>
        </div>
        <div className="vision-item"
        >
          <div className="vision-img-div-mobile">
            <img src={`${IMG_URL}/vision3.png`} />

            <div className="vision-mobile-txt">소통과 나눔</div>
          </div>
        </div>
        <div className="vision-item">
          <div className="vision-img-div-mobile">
            <img src={`${IMG_URL}/vision4.png`} />
            <div className="vision-mobile-txt">맞춤 컨설팅</div>
          </div>
        </div>
      </Slider>
    )
  }
}