import React, { Component } from 'react';
import { IMG_URL } from '../js/lib';
import '../css/index.css'
import '../css/notfound.css'
export default class NotFound extends Component{
  render() {
    return (
  <div class="container">
    <img src={`${IMG_URL}/flower.png`} class="img1"/>
    <img src={`${IMG_URL}/flower.png`} class="img2"/>
    <div>
      <div class="txt">PAGE NOT FOUND</div>
      <div class="btn"><a href="/">GO HOME</a></div>
    </div>
  </div>
    )
  }
}