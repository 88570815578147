import React, { Component,useRef,useEffect,useState } from "react";
import { IMG_URL, TEL } from '../js/lib';
import "../css/first.css";
import MediaQuery from 'react-responsive'
// import { SectionsContainer } from "react-fullpage";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PhoneClick } from "../js/gtag";
export class First extends Component {

  render() {
    return (
      <section className="first-section" >
        <div className="section-bg" data-aos="fade-up">
          <div className="hg-first">
            <div className="hg-first-fir">
              부산 경남 <span>전지역</span> 장례식장과 협약 체결<br />
            </div>
            부산 전지역 장례식장
          </div>
          <div className="hg-sm"><span>100만원에 모든 장례 진행</span></div>
          <div className="hg-sm"><span>버스ㆍ리무진 세트 기본제공</span></div>
          {/* <span>30% - 무료까지 할인</span> */}
          <div>
            <div className="sm-txt">
              최고의 장례서비스로 사전상담시 30% - 무료<br /> 부산 전지역 장례식장 사용료를 할인해드립니다.
            </div>
          </div>
        </div>
        <a
          className='call-btn-outer'
          href={`tel:${TEL}`}
          onClick={() => {
            PhoneClick();
          }}
          >
          <div
          // onClick={()=>{EventTrigger("click","call","call",0)}}
          >
            <div
              className="call-btn  tel-click">
              지금 바로 상담하기</div>
          </div>
        </a>

        {/* <div className="mouse">
        <a>
          <span>
          </span>
        </a>
      </div> */}
      </section>
    )
  }
}



export class Second extends Component {
  render() {
    return (
      <section className="second-section">
        <div className="section-bg"  >
          <div className="hg-first">
            부산 경남 전지역에서 상조비용
          </div>
          <span>사전 상담 100만원</span>
          <div>
            <div className="sm-txt">
              대한 장례의전에서<br /> 최고의 장례서비스를 제공해드립니다.
            </div>
          </div>
        </div>
        <a
          className='call-btn-outer'
          href={`tel:${TEL}`}
          onClick={() => {
            PhoneClick();
          }}
        >
          <div
          // onClick={()=>{EventTrigger("click","call","call",0)}}
          >
            <div
              className="call-btn  tel-click">
              지금 바로 상담하기</div>
          </div>
        </a>

        {/* <div className="mouse">
        <a>
          <span>
          </span>
        </a>
      </div> */}
      </section>
    )
  }
}



export class Third extends Component {
  render() {
    return (
      <section className="third-section">
        <div className="section-bg">
          <div className="hg-first">
            가족의 마음으로 함께합니다.

          </div>
          <span>25년 전통의
            <MediaQuery minWidth={700}>
              &nbsp;
            </MediaQuery>
            <MediaQuery maxWidth={700}>
              <br />
            </MediaQuery>
            대한장례의전</span>
          <div>
            <div className="sm-txt">
              가족을 대하는 마음으로 함께 하겠습니다.<br />
              마음을 담은 고객 감동 서비스,<br />
              지금 바로 확인해보세요.
            </div>
          </div>
        </div>
        <a
          className='call-btn-outer'
          href={"./product"}>
          <div
          // onClick={()=>{EventTrigger("click","call","call",0)}}
          >
            <div
              className="call-btn">
              상품 바로보기</div>
          </div>
        </a>

        {/* <div className="mouse">
        <a>
          <span>
          </span>
        </a>
      </div> */}
      </section>
    )
  }
}



export function MainBG(){
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const containerRef = useRef(null);
  var settings = {
    vertical: true,
    // verticalSwiping: true,
    infinite: false,
    fade:true,
    dots: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1, // 한 번에 보여질 슬라이드 수
    slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 수
    
  };
  useEffect(() => {
    // 슬라이더가 마운트될 때 스크롤 이벤트 핸들러를 등록합니다.
    const container = containerRef.current;
    
    let startX, startY;
  
    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    };
    
    const handleTouchMove = (e) => {
      const deltaX = e.touches[0].clientX - startX;
      const deltaY = e.touches[0].clientY - startY;
      const sliderLength = sliderRef.current.innerSlider.state.slideCount;
      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        if (sliderLength-1 > currentSlide) { // 추가된 부분
          e.preventDefault();
        }
    
        if (deltaY < 0 ) {
          
          if (currentSlide < 2) {
            sliderRef.current.innerSlider.list.scrollIntoView({ behavior: 'smooth', block: 'start' });
            sliderRef.current.slickNext();
            setCurrentSlide(currentSlide + 1);
          } else {//마지막
          }
          
        } else if (deltaY > 0 ) {
          // console.log("Scroll up");
          if (currentSlide > 0) {
            sliderRef.current.slickPrev();
            setCurrentSlide(currentSlide - 1);
          } else {//첫번째슬라이드
          }
        }
      }
    };
    
    const handleWheel = (e) => {
      const deltaY = e.deltaY;
      const sliderLength = sliderRef.current.innerSlider.state.slideCount;
  
      if (
        (deltaY < 0 && currentSlide === 0) ||
        (deltaY > 0 && currentSlide === sliderLength - 1)
      ) {
        return;
      }
  
      e.preventDefault();
  
      if (deltaY < 0) {
        if (currentSlide > 1) {
          sliderRef.current.innerSlider.list.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setCurrentSlide(currentSlide - 1);
        }
        sliderRef.current.slickPrev();
      } else {
        sliderRef.current.slickNext();
      }
    };
  
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchmove', handleTouchMove);
    container.addEventListener('wheel', handleWheel);
  
    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('wheel', handleWheel);
    };
  }, [currentSlide]);
  

  const onSliderAfterChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };
    return (
      <div ref={containerRef} style={{width:"100%"}}>
        <Slider
          ref={sliderRef}
           afterChange={onSliderAfterChange}
          {...settings}>
        <First />
        <Second />
          <Third />
          </Slider>
      </div>
    )
}