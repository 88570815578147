import React, { Component } from "react";
import {Helmet} from "react-helmet";
import '../css/review.css';
import { IMG_URL } from "../js/lib";
import { drawList } from "../js/review";

export default class Review extends Component {
  componentDidMount() {
    // chk();
    drawList();
}
  render() {
    return (
      <div className="inner">
        <section
          style={{ 'backgroundImage': `url(${IMG_URL}/bg3.png)` }}
          className="cm-section" data-aos="fade-up">
          <div className="cm-icon"><img src={`${IMG_URL}/review.svg`} /></div>
          <div className="main-title">이용후기</div>
          <div className="sub-title">고객님의 따뜻한 내용이 담긴<br /> 소중한 후기에 감사드립니다.</div>
          <div className="mouse" >
            <a >
              <span style={{'border': '2px solid white'}} >
              </span>
            </a>
          </div>
        </section>

        <div className="review-section-2" id="review-section">

        </div>
        
      </div>
    )
  }
}